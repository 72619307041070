import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Button, Container, Divider, Grid, ListItemIcon, MenuItem } from '@mui/material';
import { connect } from 'react-redux';
import { get_portal_users } from '../redux.js/actions';
import LoadingSpinner from '../components/loader/LoadingSpinner';
import { headingColor, secondaryColor } from '../util/colors';
import AccountMenu from '../components/Common/MoreMenu';
import { Delete, Update } from '@mui/icons-material';
import UpdateUser from '../components/Users/UpdateUser';
const Portal_users = (props) => {
  const [rows, setRows] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdateUser, setIsUpdateUser] = useState(null)
  const [values, setValues] = useState({
    full_name: '',
    email: '',
    phone: '',
    address: '',
    username: '',
    password: '',
    confirm_password: '',
    role_id: '',
    user_type: "",
    district: '',
  });
  const onPressUpdate = (row) => {
    setIsUpdateUser(row)
  }
  const handleDelete = (row) => {

  }
  const columns = [
    { field: 'id', headerName: 'Id', flex: 1 },
    { field: 'full_name', headerName: 'Name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'phone', headerName: 'Phone', flex: 1 },
    { field: 'district_title', headerName: 'District', flex: 1 },
    {
      field: 'role_id',
      headerName: 'Role Name',
      flex: 1,
      renderCell: (params) => {
        const role = props?.all_dropdowns?.roles?.find(role => role.role_id == params.row.role_id);
        return (
          <div>
            {role ? role.role_name : 'Unknown Role'}
          </div>
        );
      }
    },
    { field: 'created_on', headerName: 'Created On', flex: 1 },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      renderCell: (params) => (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
          <AccountMenu>
            <MenuItem onClick={() => onPressUpdate(params.row)}>
              <ListItemIcon>
                <Update fontSize="small" />
              </ListItemIcon>
              Update
            </MenuItem>
            <Divider />
            {params.row.user_type != 'super_admin' &&
              <MenuItem onClick={() => handleDelete(params.row)}>
                <ListItemIcon>
                  <Delete fontSize="small" />
                </ListItemIcon>
                Delete
              </MenuItem>
            }
          </AccountMenu>
        </div>
      ),
    },
  ];

  useEffect(() => {
    GetWebUsers()
  }, [])
  const GetWebUsers = async () => {
    setIsLoading(true)
    let result = await props.get_portal_users(`api/get_portal_user?id=${props.user_data.id}&user_type=${props.user_data.user_type}`, props.token)
    setIsLoading(false)
    if (result) {
      setRows(result.data)
    } else {
      setRows([])
    }
  }
  return (
    <Container sx={{ pt: 5 }}>
      <Grid container columns={12} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <h4 style={{ color: headingColor, fontWeight: 'bold' }}>User Managment</h4>
        {/* <button className='heading_button' onClick={() => { }}>Create New User</button> */} </Grid>
      <DataGrid rows={rows} columns={columns}
        slots={{ toolbar: GridToolbar }}
        autoHeight
        rowHeight={50}
      />
      {isUpdateUser &&
        <UpdateUser
          isOpen={isUpdateUser ? true : false}
          onClose={() => setIsUpdateUser(null)}
          formData={isUpdateUser}
        />
      }
      {isLoading &&
        <LoadingSpinner />
      }
    </Container>

  );
}
const mapStateToPros = (state) => ({
  user_data: state.AuthReducer.user_data,
  token: state.AuthReducer.token,
  all_dropdowns: state.rootReducer.all_dropdowns,
})
const mapStateToDispatch = {
  get_portal_users: get_portal_users
}
export default connect(mapStateToPros, mapStateToDispatch)(Portal_users) 